import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import { Badge, Button, Card, Group, LoadingOverlay, Stack, Text } from "@mantine/core";
import { SectionBox } from "components/v2/SectionBox";
import React from "react";
import { useQuery } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";

export function TeleportLayerProcessingRequests({layer}) {

    const {data: processingRequests, isLoading} = useQuery(['teleport-layer-processing-requests', layer.id], () => {
        return BackendServiceV2.get(`/layer/${layer.id}/process`);
    });

    return (
        <SectionBox 
            title="Histórico"
        >
            <LoadingOverlay visible={isLoading} />

            {processingRequests && 
                <Stack>
                    {processingRequests?.map(request => (
                        <Card withBorder>
                            <Stack>
                                <Group position='apart'>
                                    <Group>
                                        <Badge variant='filled' size='sm'>{request.id}</Badge>
                                        <Badge size='sm'>{request.status}</Badge>
                                        {request.skyboxes && <Badge size='sm'>Skyboxes</Badge>}
                                        {request.skyboxUpdateOnly && <Badge size='sm'>Somente atualizar</Badge>}
                                        {request.models && <Badge size='sm'>Modelos</Badge>}
                                    </Group>
                                    <Group spacing={3}>
                                        <Text size="xs" color='dimmed' weight={600}>Iniciado por: </Text>
                                        <Text size="xs">{request.requestedBy?.name}</Text>
                                    </Group>
                                </Group>
                                <Group grow noWrap position='apart'>
                                    <Stack spacing={0}>
                                        <Text size="sm" weight={600}>Criado</Text>
                                        {request.createdAt && <Text size="xs">{dayjs(request.createdAt).locale('pt-br').format('DD/MM/YYYY HH:mm')}</Text>}
                                    </Stack>
                                    <Stack spacing={0}>
                                        <Text size="sm" weight={600}>Iniciado</Text>
                                        {request.startedAt ? 
                                            <Text size="xs">{dayjs(request.startedAt).locale('pt-br').format('DD/MM/YYYY HH:mm')}</Text> 
                                        : 
                                            <Text color='dimmed' size="xs">Não iniciado</Text>
                                        }
                                    </Stack>
                                    <Stack spacing={0}>
                                        <Text size="sm" weight={600}>Finalizado</Text>
                                        {request.finishedAt ? 
                                            <Text size="xs">{dayjs(request.finishedAt).locale('pt-br').format('DD/MM/YYYY HH:mm')}</Text> 
                                        : 
                                            <Text color='dimmed' size="xs">Não finalizado</Text>
                                        }
                                    </Stack>
                                </Group>

                                {request.input &&
                                    <Button component='a' href={window.ENV.StaticURL + request.input}>Baixar original</Button>
                                }

                                {request.vrJson &&
                                    <Button component='a' href={window.ENV.StaticURL + request.vrJson}>Baixar vr.json</Button>
                                }
                            </Stack>
                        </Card>
                    ))}
                </Stack>
            }
        </SectionBox>
    )
}