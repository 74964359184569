import { Badge, Button } from "@mantine/core";
import { FixedHeightContentWrapper, FixedHeightLeftContent, FixedHeightRightContent } from "components/v2/Dashboard/Layout";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React, { useContext, useEffect, useState } from "react";
import { TeleportLayerCreateAndEdit } from "../TeleportLayerCreateAndEdit";
import { TeleportLayerCreateLayerChildButton } from "../TeleportLayerCreateChildButton";
import { TeleportLayerDeleteButton } from "../TeleportLayerDeleteButton";
import { TeleportLayerList } from "../TeleportLayerList";
import { TeleportLayerProcessing } from "../TeleportLayerProcessing";
import { TeleportLayerSkyboxes } from "../TeleportLayerSkyboxes";
import { TeleportLayerProcessingRequests } from "../TeleportLayerProcessingRequests";
import { TeleportLayerSalesMap } from "../TeleportLayerSalesMap";

export function TeleportLayerContent({idTeleport, layer}) {

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    // ACTIVE LAYER
    const [activeLayer, setActiveLayer] = useState();

    useEffect(() => {
        if(layer)
            setActiveLayer(layer);
    // eslint-disable-next-line
    }, [])

    return (
        <FixedHeightContentWrapper>
            <FixedHeightLeftContent title="Layers">
                <TeleportLayerList layer={layer} setActiveLayer={setActiveLayer} moveable={false} />
            </FixedHeightLeftContent>

            {activeLayer && 
                <FixedHeightRightContent 
                    title={activeLayer.name}
                    badges={<Badge>{activeLayer.id}</Badge>}
                    right={<>
                        <Button variant="subtle" component="a" href="https://whimsical.com/layers-JGNpoofu1ChyndV5ZzmYg6" target="_blank">Aprenda a enviar arquivos</Button>
                        <TeleportLayerDeleteButton layer={activeLayer} />
                        <TeleportLayerCreateLayerChildButton idTeleport={idTeleport} layer={activeLayer} />
                    </>}
                >
                    {resourcePermission.containsPermission('TELEPORT_LAYER_UPDATE') && activeLayer.type !== 'GROUP' && 
                        <TeleportLayerProcessing layer={activeLayer} />
                    }

                    {resourcePermission.containsPermission('TELEPORT_LAYER_UPDATE') && activeLayer.type !== 'GROUP' && 
                        <TeleportLayerProcessingRequests layer={activeLayer} />
                    }

                    {resourcePermission.containsPermission('TELEPORT_LAYER_UPDATE') && activeLayer.type !== 'GROUP' && 
                        <TeleportLayerSalesMap layer={activeLayer} />
                    }

                    {resourcePermission.containsPermission('TELEPORT_LAYER_UPDATE') &&
                        <TeleportLayerCreateAndEdit updateLayerData={activeLayer} />
                    }

                    {resourcePermission.containsPermission('TELEPORT_LAYER_READ') && activeLayer.type !== 'GROUP' && 
                        <TeleportLayerSkyboxes layer={activeLayer} />
                    }
                </FixedHeightRightContent>
            }
        </FixedHeightContentWrapper>
    )
}